var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑MB账号",
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "500px",
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "MB账号", prop: "account" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: { maxlength: "16", placeholder: "请输入账号" },
                    model: {
                      value: _vm.form.account,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "account", $$v)
                      },
                      expression: "form.account",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "pwd" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: { maxlength: "16", placeholder: "请输入密码" },
                    model: {
                      value: _vm.form.pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pwd", $$v)
                      },
                      expression: "form.pwd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }